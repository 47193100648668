import engineeringEndpoints from "@/store/endpoints/engineeringEndpoints";
import {PDF} from "@/types/printFormats";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";

export default {
  async getStructureByProduct(context, productId) {
    const data = {
      params: {
        format: PDF.value,
        productId: productId,
      },
      endpoint: '/product_structure',
    }
    const response = await reportsEndpoints.getReport(data)
    if (response.status === 200) reportOutputResolver.openFile(response)
  },

  async getProductReverseFormulas(context, productId) {
    const data = {
      params: {
        productId: productId,
      },
      endpoint: '/product_reverse_formula_report',
    }
    const response = await reportsEndpoints.getReport(data)
    if (response.status === 200) reportOutputResolver.openFile(response)
  },

  async getFormulasByProduct(context, productId) {
    const response = await engineeringEndpoints.getFormulasByProduct(productId)
    if (response.status === 200 && response.data) {
      context.commit('loadFormulaChildren', response.data.data.children)
    }
    return response
  },

  async createOrEditFormula(context, formulaData) {
    if (formulaData.isBatch) {
      return await engineeringEndpoints.createOrEditFormulaBatch(formulaData)
    }
    return await engineeringEndpoints.createOrEditFormula(formulaData.vector)
  },
  async deleteFormula(context, params) {
    return await engineeringEndpoints.deleteFormula(params)
  },
}