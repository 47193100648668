export default {
    getPeriodClose(state) {
        return state.periodClose
    },
    getPaymentMethods(state) {
        return state.paymentMethods
    },
    getCollectionMethods(state) {
        return state.collectionMethods
    },
    getAccountPlannings(state) {
        return state.accountPlannings
    },
    getLedgerAccountMovements(state) {
        return state.ledgerAccountMovements
    },
    getLedgerDebitTotal(state) {
        return state.ledgerAccountBalances.debitTotal
    },
    getLedgerCreditTotal(state) {
        return state.ledgerAccountBalances.creditTotal
    },
    getSubCategories(state) {
        return state.subCategories
    },
    getCommonAccountingEntries(state) {
        return state.commonAccountingEntries
    },
    getPaymentMethodAccountParameters(state) {
        return state.paymentMethodAccountParameters
    }
}