export default {
    getWorkOrders(state) {
        return state.workOrders
    },
    getPendingProductionOrdersByProduct(state) {
        return state.pendingProductionOrdersByProduct
    },
    getWorkOrdersByNumber(state) {
        return number => state.workOrders.filter(order => order.number.toString().includes(number))
    },
    getWorkOrdersByCustomer(state) {
        return customer => state.workOrders.filter(order =>
            order.customer.socialReason.toLowerCase().includes(customer.toLowerCase()))
    },
    getRelatedOrders(state) {
        return state.relatedOrders
    },
    getProgrammedOrders(state) {
        return state.programmedOrders
    },
    getEmittedOrders(state) {
        return state.emittedOrders
    },
    getBarPlateCalculations(state) {
       return state.barPlateCalculations
    },
    getTubePanelCalculations(state) {
        return state.tubePanelCalculations
    },
    getIntercoolerCalculations(state) {
        return state.intercoolerCalculations
    },
    getProductionTubePanels(state) {
        return state.productionTubePanels
    },
    getProductionIntercoolers(state) {
        return state.productionIntercoolers
    },
    getProductionBarPlates(state) {
        return state.productionBarPlates
    },
    getOperationsByCode(state) {
        return state.operationsByCode
    },
    getRepairs(state) {
        return state.repairs
    },
    getRepairConcepts(state) {
        return state.repairConcepts
    },
}