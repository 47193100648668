export default {
    addPeriodClose(state, periodClose) {
        state.periodClose.push(periodClose)
    },

    removePeriodClose(state, periodClose) {
        state.periodClose.splice(state.periodClose.indexOf(periodClose), 1)
    },

    loadPeriodClose(state, periodClose) {
        state.periodClose = periodClose
    },

    async loadPaymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods
    },
    async loadCollectionMethods(state, collectionMethods) {
        state.collectionMethods = collectionMethods
    },
    async loadAccountPlannings(state, accountPlannings) {
        state.accountPlannings = accountPlannings
    },
    async loadLedgerAccountMovements(state, movements) {
        state.ledgerAccountMovements = movements
    },
    async loadLedgerAccountBalances(state, data) {
        state.ledgerAccountBalances.debitTotal = data.balances.debitTotal
        state.ledgerAccountBalances.creditTotal = data.balances.creditTotal
    },

    removeAccountPlannings(state, account) {
        let index = state.accountPlannings.indexOf(account)
        state.accountPlannings.splice(index, 1)
    },

    async loadSubCategories(state, subCategories) {
        state.subCategories = subCategories
    },
    async loadCommonAccountingEntries(state, commonAccountingEntries) {
        state.commonAccountingEntries = commonAccountingEntries
    },
    async loadPaymentMethodAccountParameters(state, paymentMethodAccountParameters) {
        state.paymentMethodAccountParameters = paymentMethodAccountParameters
    },

    async updateAccountingEntry(state, accountData) {
        Object.assign(state.commonAccountingEntries[accountData.index], accountData.accountEntry)
    },
}