import {PES} from "@/types/currencies";

export function money(currency = PES.value) {
  return {
    currency: currency,
    amount: '0'
  }
}

export function sumAmounts(amountA, amountB) {
  const total = Number(amountA) + Number(amountB)
  return total.toString()
}

export function subtractAmounts(amountA, amountB) {
  const total = Number(amountA) - Number(amountB)
  return total.toString()
}

export function multiplyAmountByQuantity(amount, quantity) {
  const total = Number(amount) * Number(quantity)
  return total.toString()
}

export function isFirstAmountGreater(first, second) {
  return Number(first) > Number(second)
}

export function isFirstAmountEqualOrGreater(first, second) {
  return Number(first) >= Number(second)
}

export function toFixedTwoDecimals(number) {
  return Number(number).toFixed(2)
}

export function calculateAmountsTotal(amounts) {
  let total = 0
  amounts.forEach(amount => {
    total += Number(amount)
  })
  return total.toString()
}