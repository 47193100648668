import store from "@/store";

export function loadBaseResources() {
    if (localStorage.getItem('user-token')) {
        store.dispatch('app/getSectorsByUser')
        store.dispatch('app/getParameters')
        store.dispatch('customers/getCustomers')
        store.dispatch('suppliers/getSuppliers')
        store.dispatch('people/getProvinces')
    }
}