import productionEndpoints from "@/store/endpoints/productionEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getWorkOrders(context, params) {
        const response = await productionEndpoints.getWorkOrders(params)
        if (response.status === 200) {
            context.commit('loadWorkOrders', response.data.data)
            setTablePagination(context, response.data)
        }
        return response.data.data
    },
    async getPendingProductionOrdersByProduct(context, productId) {
        const response = await productionEndpoints.getPendingProductionOrdersByProduct(productId)
        if (response.status === 200) context.commit('loadPendingProductionOrdersByProduct', response.data)
    },
    async getProductionOrderReport(context, data) {
        const response = await productionEndpoints.getProductionOrderReport(data.orderId)
        if (response.status === 200) {
            if (data.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getProductionOrderBatchReport(context, orderIds) {
        orderIds = orderIds.join(",")
        const response = await productionEndpoints.getProductionOrderBatchReport(orderIds)
        if (response.status === 200) {
            reportOutputResolver.downloadFile(response)
        }
    },

    async getProgrammedOrders(context, params) {
        const response = await productionEndpoints.getProgrammedOrders(params)
        if (response.status === 200) {
            context.commit('loadProgrammedOrders', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getEmittedOrders(context, params) {
        const response = await productionEndpoints.getEmittedOrders(params)

        if (response.status === 200) {
            context.commit('loadEmittedOrders', response.data.data)
            setTablePagination(context, response.data)
        }
    },

    async getRelatedOrdersByNumber(context, number) {
        const response = await productionEndpoints.getRelatedOrdersByNumber(number)
        if (response.status === 200) context.commit('loadRelatedOrders', response.data)
    },
    async getBarPlateCalculations(context) {
        const response = await productionEndpoints.getBarPlateCalculations()
        if (response.status === 200) context.commit('loadBarPlateCalculations', response.data)
    },
    async getBarPlateCalculationByProduct(context, productId) {
        return await productionEndpoints.getBarPlateCalculationByProduct(productId)
    },
    async getBarPlatePanelSpreadsheet(context, barPlate) {
        const response = await productionEndpoints.getBarPlatePanelSpreadsheet(barPlate)
        if (response.status === 200) reportOutputResolver.downloadFile(response)
    },
    async getTubePanelCalculations(context) {
        const response = await productionEndpoints.getTubePanelCalculations()
        if (response.status === 200) context.commit('loadTubePanelCalculations', response.data)
    },
    async getTubePanelCalculationByProduct(context, productId) {
        return await productionEndpoints.getTubePanelCalculationByProduct(productId)
    },
    async getTubePanelSpreadsheet(context, tubePanel) {
        const response = await productionEndpoints.getTubePanelSpreadsheet(tubePanel)
        if (response.status === 200) reportOutputResolver.downloadFile(response)
    },
    async getIntercoolerCalculations(context) {
        const response = await productionEndpoints.getIntercoolerCalculations()
        if (response.status === 200) context.commit('loadIntercoolerCalculations', response.data)
    },
    async getIntercoolerCalculationByProduct(context, productId) {
        return await productionEndpoints.getIntercoolerCalculationByProduct(productId)
    },
    async getIntercoolerSpreadsheet(context, intercooler) {
        const response = await productionEndpoints.getIntercoolerSpreadsheet(intercooler)
        if (response.status === 200) reportOutputResolver.downloadFile(response)
    },
    async getProductionTubePanels(context, params) {
        const response = await productionEndpoints.getProductionTubePanels(params)
        if (response.status === 200) {
            context.commit('loadProductionTubePanels', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getProductionIntercoolers(context, params) {
        const response = await productionEndpoints.getProductionIntercoolers(params)
        if (response.status === 200) {
            context.commit('loadProductionIntercoolers', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getProductionBarPlates(context, params) {
        const response = await productionEndpoints.getProductionBarPlates(params)
        if (response.status === 200) {
            context.commit('loadProductionBarPlates', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getProductionBarPlateList() {
        const response = await productionEndpoints.getProductionBarPlateList()
        if (response.status === 200) reportOutputResolver.openFile(response)
    },
    async getProductionTubePanelList() {
        const response = await productionEndpoints.getProductionTubePanelList()
        if (response.status === 200) reportOutputResolver.openFile(response)
    },
    async getProductionIntercoolerList() {
        const response = await productionEndpoints.getProductionIntercoolerList()
        if (response.status === 200) reportOutputResolver.openFile(response)
    },
    async getOperationsByCode(context, code) {
        const response = await productionEndpoints.getOperationsByCode(code)
        if (response.status === 200) context.commit('loadOperationsByCode')
    },
    async getRepairs(context, pagination) {
        const response = await productionEndpoints.getRepairs(pagination)
        if (response.status === 200) {
            context.commit('loadRepairs', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getRepairConcepts(context) {
        const response = await productionEndpoints.getRepairConcepts()
        if (response.status === 200) context.commit('loadRepairConcepts', response.data)
    },

    async createProductionOrderBatch(context, orderBatch) {
        return await productionEndpoints.createProductionOrderBatch(orderBatch)
    },

    async createOrEditProductionOrder(context, order) {
        return await productionEndpoints.createOrEditProductionOrder(order)
    },
    async updateProgrammedOrder(context, data) {
        const response = await productionEndpoints.updateProgrammedOrder(data.editingOrder)
        if (response.status === 200 && data.index > -1) context.commit('updateProgrammedOrder', data)
    },
    async updateEmittedOrder(context, data) {
        const response = await productionEndpoints.updateEmittedOrder(data.editingOrder)
        if (response.status === 200 && data.index > -1) context.commit('updateEmittedOrder', data)
    },

    async deleteProductionOrder(context, deleteData) {
        const response = await productionEndpoints.deleteProductionOrder(deleteData)
        if (response.status === 200) context.commit('removeProductionOrder', deleteData.index)
        return response
    },

    async associateProductionCustomerOrder(context, assoc) {
        const response = await productionEndpoints.associateProductionCustomerOrder(assoc)
        if (response.status === 200) {
            assoc.order.customerOrderDetailCollection.push(assoc.customerOrderDetail)
            assoc.customerOrderDetail.productionOrderCollection.push(assoc.order)
        }
    },
    async disassociateProductionCustomerOrder(context, assoc) {
        const response = await productionEndpoints.disassociateProductionCustomerOrder(assoc)
        if (response.status === 200) {
            assoc.order.customerOrderDetailCollection.splice(
                assoc.order.customerOrderDetailCollection.indexOf(assoc.customerOrderDetail, 1)
            )
            assoc.customerOrderDetail.productionOrderCollection.splice(
                assoc.customerOrderDetail.productionOrderCollection.indexOf(assoc.order), 1
            )
        }
    },
    async associateProductionOrders(context, assoc) {
        const response = await productionEndpoints.associateProductionOrders(assoc)
        if (response.status === 200) {
            assoc.childOrder.parentOrders.push(assoc.parentOrder)
            assoc.parentOrder.myOrders.push(assoc.childOrder)
        }
    },
    async disassociateProductionOrders(context, assoc) {
        const response = await productionEndpoints.disassociateProductionOrders(assoc)
        if (response.status === 200) {
            assoc.childOrder.parentOrders.splice(assoc.childOrder.parentOrders.indexOf(assoc.parentOrder), 1)
            assoc.parentOrder.myOrders.splice(assoc.childOrder.myOrders.indexOf(assoc.childOrder), 1)
        }
        return response
    },

    async createOrEditBarPlate(context, barPlate) {
        return await productionEndpoints.createOrEditBarPlate(barPlate)
    },
    async createOrEditTubePanel(context, tubePanel) {
        return await productionEndpoints.createOrEditTubePanel(tubePanel)
    },
    async createOrEditIntercooler(context, intercooler) {
        return await productionEndpoints.createOrEditIntercooler(intercooler)
    },

    async updateProductionControlBarPlate(context, bar) {
        return await productionEndpoints.updateProductionControlBarPlate(bar)
    },
    async updateProductionControlTubePanel(context, tube) {
        return await productionEndpoints.updateProductionControlTubePanel(tube)
    },
    async updateProductionControlIntercooler(context, intercooler) {
        return await productionEndpoints.updateProductionControlIntercooler(intercooler)
    },
    async updateOperation(context, operation) {
        return await productionEndpoints.updateOperation(operation)
    },

    async createOrEditRepairConcept(context, concept) {
        const response = await productionEndpoints.createOrEditRepairConcept(concept)
        if (response.status === 201) context.commit('addRepairConcept', concept)
        return response
    },
    async deleteRepairConcept(context, index) {
        const concept = context.state.repairConcepts[index]
        const response = await productionEndpoints.deleteRepairConcept(concept.id)
        if (response.status === 200) context.commit('removeRepairConcept', index)
    },
    async updateRepairItemStatus(context, repair) {
        return await productionEndpoints.updateRepairItemStatus(repair)
    }
}