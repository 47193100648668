import suppliersEndpoints from "@/store/endpoints/suppliersEndpoints";
import financesEndpoints from "@/store/endpoints/financesEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";
import customersEndpoints from "@/store/endpoints/customersEndpoints";

export default {
    async getSuppliers(context) {
        const response = await suppliersEndpoints.getSuppliers()
        if (response.status === 200) {
            context.commit('loadSuppliers', response.data)
        }
    },
    async getSupplierData(context, id) {
        const response = await suppliersEndpoints.getSupplierData(id)
        return response.data
    },
    async getCostCenterImputations(context) {
        const response = await suppliersEndpoints.getCostCenterImputations()
        if (response.status === 200) context.commit('loadCostCenterImputations', response.data)
    },
    async getThirdPartyChecks(context, params) {
        const response = await financesEndpoints.getThirdPartyChecks(params)
        if (response.status === 200) {
            context.commit('loadThirdPartyChecks', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getSupplierCurrentAccountDetails(context, params) {
        const response = await suppliersEndpoints.getSupplierCurrentAccountDetails(params)
        if (response.status === 200) {
            context.commit('loadSupplierCurrentAccountDetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getSupplierProofData(context, id) {
        const response = await suppliersEndpoints.getSupplierProofData(id)
        return response.data.data
    },
    async getPaymentOrderDetail(context, printData) {
        const data = {
            params: {
                paymentOrderId: printData.paymentOrderId,
            },
            endpoint: '/payment_order_detail_report',
        }
        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getSupplierProofsForImputation(context, currentAccountId) {
        const response = await suppliersEndpoints.getSupplierProofsForImputation(currentAccountId)
        if (response.status === 200) context.commit('loadSupplierProofsForImputation', response.data)
    },
    async getSupplierProofsForCompensation(context, params) {
        const response = await suppliersEndpoints.getSupplierProofsForCompensationPaged(params)
        if (response.status === 200) context.commit('loadSupplierProofsForCompensation', response.data)
    },
    async getPaymentsAndCreditNotes(context, currentAccountId) {
        const response = await suppliersEndpoints.getPaymentsAndCreditNotes(currentAccountId)
        if (response.status === 200) context.commit('loadPaymentsAndCreditNotes', response.data)
    },
    async getImputations(context, params) {
        const response = await suppliersEndpoints.getImputations(params)
        if (response.status === 200) {
            context.commit('loadImputations', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getImputationsBySupplierProof(context, supplierProofId) {
        const params = {
            supplierProofId,
            limit: 20,
            page: 1
        }
        const response = await suppliersEndpoints.getImputations(params)
        if (response.status === 200) {
            context.commit('loadImputations', response.data.data)
        }
    },
    async getPaymentsSchedule(context, limitDate) {
        const response = await financesEndpoints.getPaymentsSchedule(limitDate)
        if (response.status === 200) context.commit('loadPaymentsSchedule', response.data)
    },
    async getOwnChecksSchedule(context, limitDate) {
        const response = await financesEndpoints.getOwnChecksSchedule(limitDate)
        if (response.status === 200) context.commit('loadOwnChecksSchedule', response.data)
    },
    async getCommercialProofDetailSubtotal(context, detail) {
        return await suppliersEndpoints.getCommercialProofDetailSubtotal(detail)
    },

    async verifyReceivedProofs(context, data) {
        const response = await suppliersEndpoints.verifyReceivedProofs(data)
        if (response.status === 200) {
            reportOutputResolver.downloadFile(response)
        }
    },

    async createSupplier(context, data) {
        const response = await suppliersEndpoints.createOrEditSupplier(data)
        if (response.status === 201) {
            data.item.attachmentCollection = response.data
            context.commit('addSupplier', data.item)
        }
        return response
    },
    async editSupplier(context, data) {
        const response = await suppliersEndpoints.createOrEditSupplier(data)
        if (response.status === 201) {
            data.item.attachmentCollection = response.data
            context.commit('updateSupplier', data)
        }
        return response
    },
    async deleteContact(context, id) {
        return await suppliersEndpoints.deleteContact(id)
    },
    async deleteCurrentAccount(context, id) {
        return await suppliersEndpoints.deleteCurrentAccount(id)
    },
    async deleteBankAccount(context, id) {
        return await suppliersEndpoints.deleteBankAccount(id)
    },
    async deleteSupplier(context, index) {
        let item = context.state.suppliers[index]
        const response = await suppliersEndpoints.deleteSupplier(item.id)
        if (response.status === 200) {
            context.commit('removeSupplier', index)
        }
    },

    async createOrEditCostCenterImputation(context, imputation) {
        const response = await suppliersEndpoints.createOrEditCostCenterImputation(imputation)
        if (response.status === 201) {
            context.commit('addCostCenterImputation', imputation)
        }
    },
    async deleteCostCenterImputation(context, index) {
        let item = context.state.costCenterImputations[index]
        const response = await suppliersEndpoints.deleteCostCenterImputation(item.id)
        if (response.status === 200) {
            context.commit('removeCostCenterImputation', index)
        }
    },

    async createOrEditCommercialSupplierProof(context, data) {
        const response = await suppliersEndpoints.createOrEditCommercialSupplierProof(data)
        if (response.status === 201 && data.index > -1) {
            data.supplierProof.attachmentCollection = response.data
        }
        return response
    },
    async deleteCommercialSupplierProofDetail(context, id) {
        return await suppliersEndpoints.deleteCommercialSupplierProofDetail(id)
    },
    async createPaymentOrder(context, payment) {
        return await suppliersEndpoints.createPaymentOrder(payment)
    },
    async createCurrentAccountBalance(context, balance) {
        return await suppliersEndpoints.createCurrentAccountBalance(balance)
    },
    async saveCurrentAccountNotes(context, supplierCurrentAccount) {
        return await suppliersEndpoints.saveCurrentAccountNotes(supplierCurrentAccount)
    },
    async deleteSupplierCurrentAccountDetail(context, index) {
        let detail = context.state.supplierCurrentAccountDetails[index]
        return await suppliersEndpoints.deleteSupplierCurrentAccountDetail(detail.id)
    },

    async createSupplierImputations(context, imputations) {
        return await suppliersEndpoints.createSupplierImputations(imputations)
    },
    async deleteSupplierImputation(context, imputationIndex) {
        const imputation = context.state.imputations[imputationIndex]
        const response = await suppliersEndpoints.deleteSupplierImputation(imputation.id)
        if (response.status === 200) context.commit('removeSupplierImputation', imputationIndex)
        return response
    },
}